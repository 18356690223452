<template>
    <PageTitleWithCountComponent v-if="hasMainLayoutInitialized" title="Properties" :totalItemCount="totalProperties"/>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    PageTitleWithCountComponent
  },
  data() {
    return {
      totalProperties: 0,
      properties: [],
    };
  },
  mounted() {
    this.loadProperties(this.currentFilters);
  },
  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },
  methods: {
    ...mapActions(PROPERTY_STORE, ['getProperties']),

    async loadProperties(filters) {
      await this.getProperties(filters).then((response) => {
        const { data, totalCount } = response;
        this.totalProperties = totalCount;
        this.properties = data;
      });
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
